import React from 'react'
import { kebabCase } from 'lodash'
import { Link, graphql } from 'gatsby'
import { Badge, Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/layout'

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout
    title={`tags`}
  >
    <Container>
      <Row>
        <Col>
          <h1 className="title is-size-2 is-bold-light">Tags</h1>
          {group.map(tag => (
            <Badge variant="primary" key={tag.fieldValue} className="mr-2 mb-2 p-2">
              <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className="h4 text-reset">
                {tag.fieldValue} <Badge variant="light" className="ml-2">{tag.totalCount}</Badge>
              </Link>
            </Badge>
          ))}
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: {
        frontmatter: {
          isPublished: {
           eq: true
          }
        }
      }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
